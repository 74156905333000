import { addSeconds, isAfter } from 'date-fns';
import hash from 'hash-it';
import { Observable } from 'rxjs';

export class HttpCacheService<T> {
  CACHE_DURATION_IN_SECONDS = 10;
  readonly DEFAULT_KEY = 'DEFAULT';

  private cache: {
    [id: string]: {
      expires: Date;
      value: Observable<T>;
    };
  } = {};

  getValue(object?: any): Observable<T> | null {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    const item = this.cache[key];
    if (!item) {
      return null;
    }

    if (isAfter(new Date(), item.expires)) {
      return null;
    }

    return item.value;
  }

  setValue(value: Observable<T>, object?: any) {
    const key = object ? hash(object).toString() : this.DEFAULT_KEY;
    const expires = new Date(addSeconds(new Date(), this.CACHE_DURATION_IN_SECONDS));
    this.cache[key] = { expires, value };
  }

  clearCache() {
    this.cache = {};
  }
}
